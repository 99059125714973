const lineChartOptions = {
  chart: {
    foreColor: "#ccc",
    toolbar: {
      show: false,
    },
  },
  markers: {
    size: 5,
    colors: ["rgba(255, 0,0, 0.5)", "rgba(0, 231, 255, 0.9)"],
    strokeColor: "#ffffff",
    strokeWidth: 3,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    curve: "smooth",
  },
  legend: {
    show: true,
    position: "top",
    horizontalAlign: "center",
    fontSize: "20px",
    fontFamily: "Montserrat",
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
  },

  yaxis: {
    title: {
      text: "# of Clients",
    },
  },

  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.5,
      opacityTo: 0.9,
      stops: [0, 90, 100],
    },
  },
  tooltip: {
    theme: "dark",
    shared: true,
  },
  colors: ["rgba(255, 0,0, 0.5)", "rgba(0, 231, 255, 0.3)"],
};

const barChartOptions = {
  colors: ["rgba(0,231, 255, 0.3)"],
  chart: {
    foreColor: "#ccc",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      //   endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  yaxis: {
    title: {
      text: "% (percent)",
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    theme: "dark",
    y: {
      formatter: function (val) {
        return val + " %";
      },
    },
  },
};

export { lineChartOptions, barChartOptions };

<template>
  <div>
    <header-slot> </header-slot>
    <b-card no-body>
      <b-row class="p-1">
        <b-col md="auto" sm="auto">
          <div class="d-inline-flex">
            <span class="pr-1 ml-2 day all-text">Month </span>
            <b-form-select
              v-model="month"
              :options="monthOpts"
              class="month-select"
              @input="setCurrentMonth"
            />
          </div>
        </b-col>
        <b-col md="auto" sm="auto">
          <div class="d-inline-flex">
            <span class="pr-1 ml-2 day all-text">Year </span>
            <b-form-select
              v-model="year"
              :options="yearOpts"
              class="month-select"
              @input="getChartData"
            />
          </div>
        </b-col>
        <b-col md="auto" sm="auto">
          <div class="d-inline-flex">
            <span class="pr-1 ml-2 day all-text" style="padding-top: 8px"
              >Type of Chart
            </span>
            <b-form-checkbox
              v-model="isPercent"
              class="mr-0 mt-50"
              name="is-rtl"
              switch
              inline
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <dash-card :items="cards" :key="changeMonth" :loading="loading" />
    <b-card no-body>
      <b-card-title class="p-1 text-uppercase title">
        <span v-if="isPercent"> % Percent </span>
        <span v-else> Accounts vs Payments </span>
      </b-card-title>
      <b-card-body>
        <b-row>
          <b-col md="12" sm="12">
            <b-overlay
              :show="loading"
              rounded="sm"
              opacity="0.5"
              :variant="isDarkSkin ? 'dark' : 'light'"
            >
              <template v-if="isPercent">
                <vue-apex-charts
                  ref="chartPercent"
                  type="bar"
                  height="550"
                  width="100%"
                  :options="percentOptions"
                  :series="percentData"
                />
              </template>
              <template v-else>
                <vue-apex-charts
                  ref="chartLine"
                  type="area"
                  height="550"
                  width="100%"
                  :options="chartOptions"
                  :series="chartData"
                />
              </template>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-row>
      <b-col cols="6" v-if="isCeo || isChief || isSupervisor">
        <call-analysis
          ref="callAnalysis"
          :select-year="year"
          :select-month="month + 1"
          :module-id="moduleId"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import Card from "@/views/credit-experts/views/dashboard/components/Card.vue";
import VueApexCharts from "vue-apexcharts";
import MaReportsService from "@/views/commons/components/reports/reports.service";
import CallAnalysis from "@/views/ceo/dashboard/CallAnalysis.vue";

import {
  lineChartOptions,
  barChartOptions,
} from "@/views/credit-experts/views/dashboard/options.chart.js";
export default {
  components: {
    vSelect,
    "dash-card": Card,
    VueApexCharts,
    CallAnalysis,
  },
  data() {
    return {
      loading: false,
      isPercent: false,
      changeMonth: false,
      currentDay: moment().format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      cards: [
        {
          title: "Accounts",
          data: 0,
          icon: "ClockIcon",
          cursor: true,
          color: "#744FEF",
          back: "background-color: rgba(143, 95, 232, 0.12)!important",
        },
        {
          title: "Paid",
          data: 0,
          icon: "CheckCircleIcon",
          cursor: true,
          color: "#40A094",
          back: "background-color: rgba(0, 210, 91, 0.12)!important",
          content: {},
          clickable: true,
          amount: 0,
        },
        {
          title: "Remaining",
          data: 0,
          icon: "DollarSignIcon",
          cursor: true,
          color: "#E84E4D",
          back: "background-color: rgba(252, 66, 74, 0.12)!important",
          content: {},
          clickable: true,
          remaining: 0,
        },
        {
          title: "Percentage",
          data: 0,
          icon: "PercentIcon",
          cursor: true,
          color: "#E67B4F",
          back: "background-color: rgba(255, 159, 67, 0.12)!important",
        },
      ],
      month: new Date().getMonth(),
      monthOpts: [
        { value: 0, text: "January" },
        { value: 1, text: "February" },
        { value: 2, text: "March" },
        { value: 3, text: "April" },
        { value: 4, text: "May" },
        { value: 5, text: "June" },
        { value: 6, text: "July" },
        { value: 7, text: "August" },
        { value: 8, text: "September" },
        { value: 9, text: "October" },
        { value: 10, text: "November" },
        { value: 11, text: "December" },
      ],
      year: moment().format("YYYY"),
      yearOpts: [],
      percentData: [
        {
          name: "Percentage",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      percentOptions: barChartOptions,
      chartData: [
        {
          name: "Pending",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Paid",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      chartOptions: lineChartOptions,
      amounts: [],
      remainings: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isSuperAdmin() {
      return this.isSupervisor || this.isTeamLeader || this.isCeo;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async getChartData() {
      this.loading = true;
      const response = await MaReportsService.getPaymentReport({
        year: this.year,
        program: this.$route.meta.program,
        programid: this.$route.meta.program,
        advisor: this.isSuperAdmin ? null : this.currentUser.user_id,
        role_id: this.isSuperAdmin ? 2 : this.currentUser.role_id,
        amount: this.amounts[this.month],
      });
      this.loading = false;
      let values = [
        "value_jan",
        "value_feb",
        "value_mar",
        "value_apr",
        "value_may",
        "value_jun",
        "value_jul",
        "value_aug",
        "value_sep",
        "value_oct",
        "value_nov",
        "value_dec",
      ];
      let accounts = response.data[1];
      let paids = response.data[2];
      let amounts = response.data[8];
      let remainings = response.data[9];
      let arrAcc = [];
      let arrPay = [];
      let arrAmount = [];
      let arrRemaining = [];
      values.forEach((value) => {
        if (accounts[value] == "-") {
          arrAcc.push(0);
        } else {
          arrAcc.push(Number(accounts[value]));
        }
        if (paids[value] == "-") {
          arrPay.push(0);
        } else {
          arrPay.push(Number(paids[value]));
        }
        if (amounts[value] == "-" || amounts[value] == null) {
          arrAmount.push(0);
        } else {
          arrAmount.push(amounts[value]);
        }
        if (remainings[value] == "-" || remainings[value] == null) {
          arrRemaining.push(0);
        } else {
          arrRemaining.push(remainings[value]);
        }
      });
      let arrPercents = arrAcc.map((_, index) => {
        let num = (arrPay[index] / arrAcc[index]) * 100;
        num = Math.round(num * 100) / 100;
        return isNaN(num) ? 0 : num;
      });
      this.chartData[0].data = arrAcc;
      this.chartData[1].data = arrPay;
      this.amounts = arrAmount;
      this.remainings = arrRemaining;
      this.percentData[0].data = arrPercents;
      if (this.isPercent) {
        this.$refs.chartPercent?.updateSeries(this.chartData);
      } else {
        this.$refs.chartLine?.updateSeries(this.chartData);
      }
      this.setCurrentMonth();
    },

    setCurrentMonth() {
      this.content = {
        year: this.year,
        month: this.month + 1,
        program: 3,
        advisor: this.isSuperAdmin ? null : this.currentUser.user_id,
        amount: this.amounts[this.month],
        remaining: this.remainings[this.month],
      };
      let accounts = this.chartData[0].data[this.month];
      let payed = this.chartData[1].data[this.month];
      this.cards[0].data = accounts;
      this.cards[1].data = payed;
      this.cards[1].amount = this.amounts[this.month];
      this.cards[1].content = this.content;
      this.cards[2].remaining = this.remainings[this.month];
      this.cards[2].data = accounts - payed;
      this.cards[2].content = this.content;
      let percent = (payed / accounts) * 100;
      this.cards[3].data = isNaN(percent) ? "0%" : percent.toFixed(2) + "%";
      this.changeMonth = !this.changeMonth;
    },
    actualMonth() {
      this.month = new Date().getMonth();
    },
  },
  async created() {
    let curYear = new Date().getFullYear();
    let startYear = curYear - 7;
    while (startYear < curYear + 2) {
      this.yearOpts.push({ value: String(startYear), text: String(startYear) });
      startYear++;
    }
    await this.actualMonth();
    await this.getChartData();
  },
};
</script>

<style scoped>
.day {
  font-weight: bold;
}
.all-text {
  margin: auto 0 !important;
}

.title {
  font-weight: 600;
  font-size: medium;
}
</style>

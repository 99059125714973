<template>
  <div>
    <b-card-group deck class="mb-2">
      <b-card
        v-for="(item, index) in items"
        :key="index"
        no-body
        :style="`border: 1px solid ${item.color}!important;`"
        class="card"
        :class="{ clickable: item.clickable && items[0].data != 0 }"
        @click="
          item.clickable && items[0].data != 0 ? openModal(item, index) : null
        "
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          opacity="0.5"
          :variant="isDarkSkin ? 'dark' : 'light'"
        >
          <b-card-body>
            <div class="d-flex align-items-end justify-content-between">
              <span class="mb-25 mt-1 title text-uppercase">
                {{ item.title }}
              </span>
              <b-avatar
                :style="`${item.back}; color: ${item.color}`"
                size="4em"
                class="icon"
              >
                <feather-icon size="21" :icon="item.icon" />
              </b-avatar>
            </div>
            <div>
              <div
                class="mt-2 mb-1"
                v-if="index === 0 || index === 3 || !showAmount || loading"
              >
                <span class="data"> {{ item.data }} </span>
              </div>
            </div>
          </b-card-body>
          <div
            v-if="(index === 1 || index === 2) && showAmount && !loading"
            class="d-inline-flex w-100"
            :style="` border-top: 1px solid ${item.color}!important;
                      border-bottom: 1px solid ${item.color}!important; 
                      margin-bottom:10px;`"
          >
            <div
              class="cuadrado"
              style="width: 50%; padding-top: 7px; padding-bottom: 7px"
              :style="`border-right: 1px solid ${item.color} !important;`"
            >
              <span class="title-mobile" style="margin-left: 20px">
                ACCOUNTS:
              </span>
              <span class="data-mobile" style="margin-left: 7px">
                {{ item.data }}
              </span>
            </div>
            <div style="width: 50%; padding-top: 7px; padding-bottom: 7px">
              <span style="margin-left: 20px" class="title-mobile">
                AMOUNT:
              </span>
              <span class="data-mobile" style="margin-left: 6px"
                >$ {{ index == 1 ? item.amount : item.remaining }}
              </span>
            </div>
          </div>
        </b-overlay>
      </b-card>
    </b-card-group>

    <!-- modal show clients -->
    <b-modal
      ref="modalShowClients"
      :title="`Clients ${this.title}`"
      size="xmd"
      hide-footer
      title-tag="h3"
      modal-class="modal-primary"
    >
      <modal-details-card :content="content" :payment="payment" />
    </b-modal>
  </div>
</template>

<script>
import ModalDetailsCard from "@/views/credit-experts/views/dashboard/components/ModalDetailsCard.vue";
export default {
  components: {
    ModalDetailsCard,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: {},
      payment: null,
      title: "",
    };
  },
  computed: {
    showAmount() {
      return this.isSupervisor || this.isTeamLeader;
    },
  },

  methods: {
    openModal(item, index) {
      this.payment = index == 2 ? 2 : 1;
      this.title = item.title;
      this.content = item.content;
      this.$refs.modalShowClients.show();
    },
  },
};
</script>
<style scoped>
.data {
  font-size: 16px;
}

.data-mobile {
  font-size: 16px;
}
.title {
  font-weight: 600;
  font-size: medium;
}

.title-mobile {
  font-size: 12px !important;
}
.cuadrado {
  border-right: 1px solid rgba(207, 190, 190, 0.5) !important;
}

.card {
  background-size: 200% auto;

  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}
@media (max-width: 1400px) {
  .title-mobile {
    font-size: 11px !important;

    margin-left: 15px !important;
  }

  .data {
    font-size: 14px;
  }

  .data-mobile {
    margin: 0.5px !important;
    font-size: 10.5px;
  }

  .icon {
    font-size: 60%;
  }

  .title {
    font-weight: 600;
    font-size: 13px;
  }

  .card {
    margin: 5px;
  }
}
</style>

<template>
  <div>
    <b-container class="px-4 mt-2 mb-2">
      <b-row class="mb-1">
        <b-col
          md="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted">
            Showing {{ paginate.startPage }} to {{ paginate.toPage }} of
            {{ totalRows }} entries
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-pagination
            v-model="paginate.currentPage"
            :total-rows="totalRows"
            :per-page="paginate.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="getReportDetails"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <b-col md="6">
          <div
            class="
              d-flex
              align-items-center
              justify-content-end
              align-items-center
            "
          >
            <b-input-group v-if="!advanceSearch" class="mr-1">
              <b-form-input
                v-model="filters.campo1"
                @keyup.enter="getReportDetails"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="getReportDetails">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              v-b-tooltip.bottom="'Advanced search'"
              variant="primary"
              @click="changeAdvanceSearch"
              v-if="false"
            >
              <div
                class="d-flex justify-content-between"
                style="white-space: nowrap"
              >
                <feather-icon icon="FilterIcon" size="15" class="mr-50" />
                {{ !advanceSearch ? "Advanced Search" : "Basic Search" }}
              </div>
            </b-button>
          </div>
        </b-col>

        <b-col md="12" class="mt-2">
          <b-row class="justify-content-end">
            <b-col md="5">
              <div>
                <label>Show</label>
                <v-select
                  v-model="paginate.perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="getReportDetails"
                />
                <label class="mr-2">entries</label>
                <feather-icon
                  class="cursor-pointer"
                  icon="RefreshCcwIcon"
                  size="20"
                  @click="getReportDetails"
                />
              </div>
            </b-col>
            <b-col v-if="!advanceSearch" md="7" />
            <b-col v-if="advanceSearch && filters.typePayment != 1" md="2" />
            <template v-if="advanceSearch">
              <b-col md="2">
                <b-form-group>
                  <v-select
                    v-model="filters.typePayment"
                    placeholder="Payment Type"
                    :options="[
                      { label: 'All', value: 0 },
                      { label: 'Automatic', value: 1 },
                      { label: 'Manual', value: 2 },
                      { label: 'Others', value: 3 },
                    ]"
                    :reduce="(el) => el.value"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="filters.typePayment == 1" md="2">
                <b-form-group>
                  <v-select
                    v-model="filters.day"
                    placeholder="Day Payment"
                    :options="[
                      { label: 'All', value: 0 },
                      { label: '5', value: 5 },
                      { label: '10', value: 10 },
                      { label: '15', value: 15 },
                      { label: '20', value: 20 },
                      { label: '25', value: 25 },
                      { label: '30', value: 30 },
                    ]"
                    :reduce="(el) => el.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group>
                  <v-select
                    v-model="filters.payment"
                    placeholder="Paid"
                    :options="[
                      { label: 'All', value: 0 },
                      { label: 'YES', value: 1 },
                      { label: 'NO', value: 2 },
                    ]"
                    :reduce="(el) => el.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click="getReportDetails"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-table :fields="table.fields" :items="details" show-empty>
      <template #cell(account)="data">
        <router-link
          target="_blank"
          :to="{
            name: programRedirect(content.program),
            params: {
              idClient: data.item.id,
            },
          }"
          v-if="content.program == 1"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          target="_blank"
          :to="{
            name: 'debt-solution-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          :class="[textLink]"
          v-else-if="moduleId === 5"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'boost-credit-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 7"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'credit-experts-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 6"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'claim-department-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 10"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'bookeeping-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 14"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'tax-research-client-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 8"
          target="_blank"
          >{{ data.item.account }}</router-link
        >
      </template>

      <template #cell(payment_type)="data">
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '1' &&
            data.item.srb == 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto.ico'"
          alt=""
        />
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '0' &&
            data.item.srb != 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
          alt=""
        />
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '1' &&
            data.item.srb != 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
          alt=""
        />
        <img
          v-if="data.item.type_payment == 'MA'"
          title="Manual"
          :src="'/assets/images/social-network/paymentType/paymenttype-manual.ico'"
          alt=""
        />
        <img
          v-if="data.item.type_payment == 'OT'"
          title="Others"
          :src="'/assets/images/social-network/paymentType/paymenttype-others.ico'"
          alt=""
        />
        <span v-if="data.item.day_payment != null">
          ({{ data.item.day_payment }})
        </span>
      </template>

      <template #cell(paid)="data">
        <span
          :style="data.item.ispayment == 1 ? 'color:#00CC00' : 'color:#FF0000'"
          >{{ data.item.ispayment == 0 ? "NO" : "YES" }}</span
        >
      </template>

      <template #cell(amount)="data">
        <ul style="padding-left: 0px; margin-bottom: 0px">
          <li
            v-for="(payment, index) in JSON.parse(data.item.payments)"
            :key="index"
            style="list-style: none"
            :class="data.item.ispayment == 0 ? 'text-danger' : ''"
          >
            {{
              payment.amount
                ? "$ " + (data.item.ispayment == 0 ? "-" : "") + payment.amount
                : ""
            }}
          </li>
        </ul>
      </template>

      <template #cell(date)="data">
        <ul style="padding-left: 0px; margin-bottom: 0px">
          <li
            v-for="(payment, index) in JSON.parse(data.item.payments)"
            :key="index"
            style="list-style: none"
          >
            <span v-if="payment.settlement_date"
              >{{ payment.settlement_date | myGlobal }}
            </span>
          </li>
        </ul>
      </template>
      <template v-slot:custom-foot>
        <b-tr class="td-totals" v-for="i in 2" :key="i">
          <b-td class="backg_footer"> </b-td>
          <b-td class="backg_footer"> </b-td>
          <b-td class="backg_footer"> </b-td>
          <b-td class="backg_footer text-right">{{
            i == 1 ? "SUB TOTAL:" : "TOTAL:"
          }}</b-td>
          <b-td class="backg_footer" v-if="payment == 1">
            $
            {{
              (i == 1 ? sub_total : total_month) == 0
                ? "0.00"
                : i == 1
                ? sub_total
                : total_month
            }}
          </b-td>
          <b-td class="text-danger backg_footer" v-if="payment == 2">
            $ -{{ i == 1 ? sub_total : total_month }}
          </b-td>
          <b-td class="backg_footer" v-if="payment == 1"> </b-td>
        </b-tr>
      </template>
    </b-table>

    <b-container class="px-4 mt-2 mb-2">
      <b-row>
        <b-col md="6">
          <b-pagination
            v-model="paginate.currentPage"
            :total-rows="totalRows"
            :per-page="paginate.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="getReportDetails"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// Components
import vSelect from "vue-select";
import MaReportsService from "@/views/commons/components/reports/reports.service";

// Services

export default {
  components: {
    vSelect,
  },
  props: {
    content: {
      type: Object,
    },
    payment: {
      type: Number,
    },
  },
  data() {
    return {
      isDetailsActive: false,
      table: {
        fields: [
          { key: "account", label: "ACCOUNT" },
          { key: "client_name", label: "NAME" },
          { key: "mobile", label: "MOBILE" },
          { key: "payment_type", label: "PAYMENT TYPE" },
          { key: "amount", label: "AMOUNT" },
          this.payment == 1 ? { key: "date", label: "DATE" } : null,
        ],
      },

      paginate: {
        currentPage: 1,
        perPage: 10,
        startPage: 0,
        toPage: 0,
      },
      perPageOptions: [10, 25, 50, 100],
      totalRows: 10,
      advanceSearch: false,

      filters: {
        campo1: "",
        day: "",
        payment: null,
        typePayment: "",
      },

      type: null,
      details: [],
    };
  },
  computed: {
    month() {
      const arrMonth = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return arrMonth[this.content.month];
    },
    total_month() {
      return this.payment == 1 ? this.content.amount : this.content.remaining;
    },
    sub_total() {
      let payments = this.details.map((item) => {
        return JSON.parse(item.payments);
      });
      let sum = payments.reduce((a, b) => {
        return a.concat(b);
      }, []);
      let sub_total = sum.reduce((a, b) => {
        return Number(a) + Number(b.amount);
      }, 0);
      return sub_total.toFixed(2);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    "filters.typePayment": function (newVal) {},
  },
  async created() {
    await this.getReportDetails();
  },
  mounted() {
    this.isDetailsActive = true;
  },
  methods: {
    changeAdvanceSearch() {
      this.advanceSearch = !this.advanceSearch;

      this.filters.campo1 = "";
      this.filters.payment = null;
      this.filters.day = null;
      this.filters.typePayment = null;
    },
    async getReportDetails() {
      try {
        this.addPreloader();
        const response = await MaReportsService.getPaymentReportDetails({
          year: this.content.year,
          month: this.content.month,
          program: this.content.program,
          advisor: this.content.advisor,
          type: this.type,
          payment: this.payment,
          campo1: this.filters.campo1,
          day: this.filters.day,
          typepayment: this.filters.typePayment,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
        });

        if (response.status == 200) {
          this.details = response.data.data;
          this.totalRows = response.data.total;
          this.paginate.startPage = response.data.from;
          this.paginate.toPage = response.data.to;

          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
    programRedirect(program) {
      let routeName = "";
      if (program == 1) {
        routeName = "bussiness-dashboard-clients";
      } else if (program == 4) {
        routeName = "debt-solution-dashboard";
      } else if (program == 2) {
        routeName = "boost-credit-dashboard";
      } else if (program == 3) {
        routeName = "credit-experts-dashboard";
      } else if (program == 8) {
        routeName = "tax-research-client-dashboard";
      }
      return routeName;
      // let routeData = this.$router.resolve({name: routeName, params: {idClient: clientId}});
      // window.open(routeData.href, '_blank');
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.font-size-09 {
  font-size: 9pt !important;
}

.sidebar-xl {
  width: 90rem !important;
}

.backg_footer {
  background: #000000af;
  color: #fff;
  font-weight: 600;
}
</style>
